<template>
	<div class="wrapper">
		<div class="container-fluid">
			<!-- Page-Title -->
			<div class="row">
				<div class="col-sm-12">
					<div class="page-title-box">
						<h2 class="page-title">Data Dasar</h2>
					</div>
				</div>
			</div>
			<!-- end page title end breadcrumb -->

			<div class="row">
				<div class="col-sm-12">
					<div>
						<div class="row">
							<div class="col-lg-2">
								<router-link :to="{ name: 'dinkes' }"
									class="btn btn-custom btn-block waves-effect waves-light w-lg m-b-5">Dinas Kesehatan
								</router-link>
								<router-link :to="{ name: 'puskesmas' }"
									class="btn btn-custom btn-block waves-effect waves-light w-lg m-b-5">Puskesmas
								</router-link>
								<router-link :to="{ name: 'backup' }"
									class="btn btn-custom btn-block waves-effect waves-light w-lg m-b-5">Backup Data
								</router-link>
								<router-link :to="{ name: 'restore' }"
									class="btn btn-custom btn-block waves-effect waves-light w-lg m-b-5">Restore Data
								</router-link>
							</div> <!-- end col -->
							<router-view></router-view>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- End container-fluid -->
		<Footer />
	</div>
</template>

<script>
import Footer from '@/views/layouts/FooterComponent.vue';

export default {
	name: 'Main',
	components: {
		Footer
	}
}
</script>